<template>
  <m-basic-form
    @save="save"
    @remove="remove"
    :loadingMsg="loadingMsg"
    :loadingShow="loadingShow"
    :formId="ID"
    :buttonLoading="buttonLoading"
  >
    <b-container fluid class="mt-5 mb-5">
      <b-row class="text-center">
        <b-col
          cols="12"
          md="4"
          class="pb-5 text-center justify-content-center side-line"
        >
          <h2>{{ $t("form.sender") }}</h2>
          <div class="line"></div>
          <b-form-input
            readonly
            :value="`${info.senderResellerCode} - ${info.senderResellerName}`"
            :placeholder="$t('form.senderagentcode')"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="selected.present"
            :options="lookup.presentData"
            :placeholder="$t('form.presentsearch')"
            label="text"
            track-by="text"
            :limit="5"
            @search-change="searchPresent"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
          </multiselect>
          <div class="divider"></div>
          <b-form-input
            v-model.lazy="senderPresentInfo.PHONE"
            v-mask="'+380 (##) ### ## ##'"
            placeholder="+380 (12) 345 67 89"
            :readonly="this.isSenderSelectedForm"
            v-if="this.isSenderSelectedForm"
          ></b-form-input>
          <div class="divider"></div>

          <template v-if="!this.isSenderSelectedForm">
            <b-row>
              <b-col cols="6">
                <b-form-input
                  v-model.lazy="senderPresentInfo.PHONE"
                  v-mask="'+380 (##) ### ## ##'"
                  placeholder="+380 (12) 345 67 89"
                  :disabled="smsOtp.isValid"
                ></b-form-input>
              </b-col>
              <b-col cols="6">
                <b-btn
                  class="yellow-button"
                  @click="requestSenderCode"
                  block
                  :disabled="
                    smsOtp.isLoading || smsOtp.startingOtp || smsOtp.isValid
                  "
                >
                  <span v-show="!smsOtp.startingOtp && !smsOtp.isLoading">{{
                    $t("form.requestcode")
                  }}</span>
                  <span v-show="smsOtp.startingOtp">{{ smsOtp.seconds }}</span>
                  <span v-show="smsOtp.isLoading">...</span>
                </b-btn>
              </b-col>
            </b-row>
            <div class="divider"></div>
            <b-form-group id="fieldset-1" label-for="code" :state="senderState">
              <b-form-input
                id="code"
                v-model="smsOtp.senderCode"
                :placeholder="$t('form.smscode')"
                :state="senderState"
                :disabled="!smsOtp.startingOtp || smsOtp.isValid"
                trim
              ></b-form-input>
              <span v-show="smsOtp.isLoadingCode">{{
                $t("form.checking")
              }}</span>
              <span v-show="smsOtp.isValid">{{ $t("form.validok") }}</span>
            </b-form-group>
            <div class="divider"></div>
          </template>
          <div class="divider"></div>
          <b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-radio
                  :disabled="isSenderSelectedForm"
                  class="radio-box"
                  v-model="senderPresentInfo.ISCOMPANY"
                  value="0"
                  >{{ $t("form.bireysel") }}</b-form-radio
                >
              </b-col>
              <b-col cols="6">
                <b-form-radio
                  :disabled="isSenderSelectedForm"
                  class="radio-box"
                  v-model="senderPresentInfo.ISCOMPANY"
                  value="1"
                  >{{ $t("form.kurumsal") }}</b-form-radio
                >
              </b-col>
            </b-row>
          </b-form-group>
          <div class="divider"></div>

          <b-row v-show="senderPresentInfo.ISCOMPANY == '0'">
            <b-col cols="4">
              <b-form-input
                :readonly="isSenderSelectedForm"
                v-model.lazy="senderPresentInfo.FIRSTNAME"
                :placeholder="$t('form.firstname')"
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                :readonly="isSenderSelectedForm"
                v-model.lazy="senderPresentInfo.LASTNAME"
                :placeholder="$t('form.lastname')"
              ></b-form-input> </b-col
            ><b-col cols="4">
              <b-form-input
                :readonly="isSenderSelectedForm"
                v-model.lazy="senderPresentInfo.SECONDNAME"
                :placeholder="$t('form.secondname')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-row v-show="senderPresentInfo.ISCOMPANY == '1'">
            <b-col cols="4">
              <b-form-select
                :disabled="isSenderSelectedForm"
                v-model="senderPresentInfo.PREFIX"
                :options="['ФОП', 'ТОВ', 'ПП', 'АТ', 'ТДВ', 'КТ']"
              ></b-form-select>
            </b-col>

            <b-col cols="8">
              <b-form-input
                :readonly="isSenderSelectedForm"
                v-model.lazy="senderPresentInfo.FIRMNAME"
                :placeholder="$t('form.firmname')"
              ></b-form-input>
            </b-col>
            <b-col cols="12">
              <b-form-input
                v-if="false"
                :readonly="isSenderSelectedForm"
                v-model="senderPresentInfo.FULLNAME"
                placeholder="Müşteri Adı Soyadı "
              ></b-form-input>
              <div class="divider"></div>
              <b-form-input
                :readonly="isSenderSelectedForm"
                v-model="senderPresentInfo.IDENTITYNO"
                :placeholder="$t('form.taxno')"
              ></b-form-input>
            </b-col>
          </b-row>

          <div class="divider"></div>

          <b-row>
            <b-col cols="9">
              <b-form-select
                v-model="senderPresentInfo.ADDRESSID"
                :options="options.senderAddress"
              ></b-form-select>
            </b-col>
            <b-col cols="3">
              <b-btn class="yellow-button" block @click="senderNewAddress">
                {{ $t("btn.new") }}
              </b-btn>
            </b-col>
          </b-row>

          <div class="divider"></div>

          <multiselect
            v-model="senderPresentInfo.region"
            :options="options.region"
            :placeholder="$t('multiselect.selectregion')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedSenderAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="senderPresentInfo.REGIONNAME"
            readonly
            v-show="isSelectedSenderAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="senderPresentInfo.city"
            :options="options.senderCity"
            :placeholder="$t('multiselect.selectcity')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedSenderAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="senderPresentInfo.CITYNAME"
            readonly
            v-show="isSelectedSenderAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="senderPresentInfo.county"
            :options="options.senderCounty"
            :placeholder="$t('multiselect.selectcounty')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedSenderAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="senderPresentInfo.COUNTYNAME"
            readonly
            v-show="isSelectedSenderAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="senderPresentInfo.town"
            :options="options.senderTown"
            :placeholder="$t('multiselect.selecttown')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedSenderAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            readonly
            :value="senderPresentInfo.TOWNNAME"
            v-show="isSelectedSenderAddressForm"
          ></b-form-input>
          <div class="divider"></div>

          <b-row>
            <b-col cols="4">
              <b-form-input
                :readonly="isSelectedSenderAddressForm"
                v-model.lazy="senderPresentInfo.BUILDINGNO"
                :placeholder="$t('form.buildingno')"
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                :readonly="isSelectedSenderAddressForm"
                v-model.lazy="senderPresentInfo.BUILDINGBLOCKNO"
                :placeholder="$t('form.buildingblockno')"
              ></b-form-input> </b-col
            ><b-col cols="4">
              <b-form-input
                :readonly="isSelectedSenderAddressForm"
                v-model.lazy="senderPresentInfo.APARTMENTNO"
                :placeholder="$t('form.apertmentnumber')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-form-textarea
            v-if="false"
            id="textarea"
            v-model="senderPresentInfo.ADDRESS"
            :placeholder="$t('form.address')"
            rows="2"
            max-rows="2"
            no-resize
            :readonly="isSelectedSenderAddressForm"
          ></b-form-textarea>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="pb-5 text-center justify-content-center side-line"
        >
          <h2>{{ $t("form.taker") }}</h2>
          <div class="line"></div>
          <multiselect
            v-model="selected.takerPresent"
            :options="lookup.takerPresentData"
            :placeholder="$t('form.presentsearch')"
            label="text"
            track-by="text"
            :limit="5"
            @search-change="takerSearchPresent"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{ $t("multiselect.nodata") }}</span>
          </multiselect>
          <div class="divider"></div>
          <b-form-input
            v-model.lazy="takerPresentInfo.PHONE"
            v-mask="'+380 (##) ### ## ##'"
            placeholder="+380 (12) 345 67 89"
            :readonly="isTakerSelectedForm"
          ></b-form-input>
          <div class="divider"></div>
          <b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-radio
                  :disabled="isTakerSelectedForm"
                  class="radio-box"
                  v-model="takerPresentInfo.ISCOMPANY"
                  value="0"
                  >{{ $t("form.bireysel") }}</b-form-radio
                >
              </b-col>
              <b-col cols="6">
                <b-form-radio
                  :disabled="isTakerSelectedForm"
                  class="radio-box"
                  v-model="takerPresentInfo.ISCOMPANY"
                  value="1"
                  >{{ $t("form.kurumsal") }}</b-form-radio
                >
              </b-col>
            </b-row>
          </b-form-group>
          <div class="divider"></div>

          <b-row v-show="takerPresentInfo.ISCOMPANY == '0'">
            <b-col cols="4">
              <b-form-input
                :readonly="isTakerSelectedForm"
                v-model.lazy="takerPresentInfo.FIRSTNAME"
                :placeholder="$t('form.firstname')"
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                :readonly="isTakerSelectedForm"
                v-model.lazy="takerPresentInfo.LASTNAME"
                :placeholder="$t('form.lastname')"
              ></b-form-input> </b-col
            ><b-col cols="4">
              <b-form-input
                :readonly="isTakerSelectedForm"
                v-model.lazy="takerPresentInfo.SECONDNAME"
                :placeholder="$t('form.secondname')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-row v-show="takerPresentInfo.ISCOMPANY == '1'">
            <b-col cols="4">
              <b-form-select
                :disabled="isTakerSelectedForm"
                v-model="takerPresentInfo.PREFIX"
                :options="['ФОП', 'ТОВ', 'ПП', 'АТ', 'ТДВ', 'КТ']"
              ></b-form-select>
            </b-col>

            <b-col cols="8">
              <b-form-input
                :readonly="isTakerSelectedForm"
                v-model.lazy="takerPresentInfo.FIRMNAME"
                :placeholder="$t('form.firmname')"
              ></b-form-input>
            </b-col>
            <b-col cols="12">
              <b-form-input
                v-if="false"
                :readonly="isTakerSelectedForm"
                v-model="takerPresentInfo.FULLNAME"
                placeholder="Müşteri Adı Soyadı "
              ></b-form-input>
              <div class="divider"></div>
              <b-form-input
                :readonly="isTakerSelectedForm"
                v-model="takerPresentInfo.IDENTITYNO"
                :placeholder="$t('form.taxno')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-form-group>
            <b-row>
              <b-col cols="6">
                <b-form-radio
                  class="radio-box"
                  v-model="form.DELIVERYTYPE"
                  value="1"
                  >{{ $t("form.deliveryaddress") }}</b-form-radio
                >
              </b-col>
              <b-col cols="6">
                <b-form-radio
                  class="radio-box"
                  v-model="form.DELIVERYTYPE"
                  value="2"
                  >{{ $t("form.deliveryagent") }}</b-form-radio
                >
              </b-col>
            </b-row>
          </b-form-group>

          <b-row>
            <b-col cols="9">
              <b-form-select
                v-model="takerPresentInfo.ADDRESSID"
                :options="options.takerAddress"
              ></b-form-select>
            </b-col>
            <b-col cols="3">
              <b-btn class="yellow-button" block @click="takerNewAddress">
                {{ $t("btn.new") }}
              </b-btn>
            </b-col>
          </b-row>

          <div class="divider"></div>

          <multiselect
            v-model="takerPresentInfo.region"
            :options="options.region"
            :placeholder="$t('multiselect.selectregion')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedTakerAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="takerPresentInfo.REGIONNAME"
            readonly
            v-show="isSelectedTakerAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="takerPresentInfo.city"
            :options="options.takerCity"
            :placeholder="$t('multiselect.selectcity')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedTakerAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="takerPresentInfo.CITYNAME"
            readonly
            v-show="isSelectedTakerAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="takerPresentInfo.county"
            :options="options.takerCounty"
            :placeholder="$t('multiselect.selectcounty')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedTakerAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            :value="takerPresentInfo.COUNTYNAME"
            readonly
            v-show="isSelectedTakerAddressForm"
          ></b-form-input>
          <div class="divider"></div>
          <multiselect
            v-model="takerPresentInfo.town"
            :options="options.takerTown"
            :placeholder="$t('multiselect.selecttown')"
            :selectLabel="$t('multiselect.pressentertoselect')"
            :selectedLabel="$t('multiselect.selected')"
            :deselectLabel="$t('multiselect.pressentertoselect')"
            label="text"
            track-by="text"
            v-show="!isSelectedTakerAddressForm"
          >
            <span slot="noResult">{{ $t("multiselect.nodata") }}</span>
            <span slot="noOptions">{{
              $t("multiselect.nodata")
            }}</span></multiselect
          >
          <b-form-input
            readonly
            :value="takerPresentInfo.TOWNNAME"
            v-show="isSelectedTakerAddressForm"
          ></b-form-input>
          <div class="divider"></div>

          <b-form-select
            v-if="form.DELIVERYTYPE == '2'"
            v-model="form.DELIVERYRESELLERID"
            :options="options.resellers"
          ></b-form-select>
          <template v-else>
            <b-row>
              <b-col cols="4">
                <b-form-input
                  :readonly="isSelectedTakerAddressForm"
                  v-model.lazy="takerPresentInfo.BUILDINGNO"
                  :placeholder="$t('form.buildingno')"
                ></b-form-input>
              </b-col>
              <b-col cols="4">
                <b-form-input
                  :readonly="isSelectedTakerAddressForm"
                  v-model.lazy="takerPresentInfo.BUILDINGBLOCKNO"
                  :placeholder="$t('form.buildingblockno')"
                ></b-form-input> </b-col
              ><b-col cols="4">
                <b-form-input
                  :readonly="isSelectedTakerAddressForm"
                  v-model.lazy="takerPresentInfo.APARTMENTNO"
                  :placeholder="$t('form.apertmentnumber')"
                ></b-form-input>
              </b-col>
            </b-row>
            <div class="divider"></div>

            <b-form-textarea
              v-if="false"
              v-model="takerPresentInfo.ADDRESS"
              :placeholder="$t('form.address')"
              rows="2"
              max-rows="2"
              no-resize
              :readonly="isSelectedTakerAddressForm"
            ></b-form-textarea>

            <div class="divider"></div>

            <b-form-input
              readonly
              :value="`${info.takerResellerCode} - ${info.takerResellerName}`"
              :placeholder="$t('form.takeragentcode')"
            ></b-form-input>
          </template>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="pb-5 text-center justify-content-center side-line"
        >
          <h2>{{ $t("form.cargoinfo") }}</h2>
          <div class="line"></div>
          <b-form-textarea
            id="textarea"
            v-model="form.DESCRIPTION"
            :placeholder="$t('form.description')"
            rows="2"
            max-rows="2"
            no-resize
          ></b-form-textarea>
          <div class="divider"></div>
          <b-form-select
            v-model="form.CARGOTYPEID"
            :options="options.cargotype"
          ></b-form-select>
          <div class="divider"></div>
          <b-row>
            <b-col cols="6">
              <b-form-select
                v-model="form.PAYTYPEID"
                :options="options.paytype"
              ></b-form-select>
            </b-col>
            <b-col cols="6">
              <b-form-select
                v-model="form.SENDINGTYPEID"
                :options="options.sendingtype"
              ></b-form-select>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-form-select
            v-model="form.PACKAGEID"
            :options="options.package"
          ></b-form-select>
          <div class="divider"></div>

          <div class="divider"></div>
          <b-form-checkbox-group
            style="text-align: left"
            v-model="form.STATICPRICES"
            value="1"
            unchecked-value="0"
            :options="options.staticPrice"
            stacked
          >
          </b-form-checkbox-group>
          <b-row>
            <b-col cols="4">
              <b-form-input
                v-model="form.PIECE"
                :placeholder="$t('form.piece')"
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="form.DESI"
                :placeholder="$t('form.deci')"
                :disabled="isDisabledDesi"
              ></b-form-input>
            </b-col>
            <b-col cols="4">
              <b-form-input
                v-model="form.WEIGHT"
                :placeholder="$t('form.weight')"
                :disabled="isDisabledDesi"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-row>
            <b-col cols="6">
              <b-form-select
                v-model="form.BOXID"
                :options="options.box"
              ></b-form-select>
            </b-col>
            <b-col cols="6">
              <div class="image-box">
                <b-img height="50" :src="boxImage"></b-img>
              </div>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-row>
            <b-col cols="6">
              <b-form-input
                v-model="coupon.COUPONCODE"
                :placeholder="$t('form.discountcoupon')"
                :readonly="coupon.isDisabledCoupon"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <b-btn
                class="blue-button"
                :disabled="coupon.isDisabledCoupon"
                block
                @click="checkCouponCode"
              >
                {{ $t("buton.checkdiscountcoupon") }}
              </b-btn>
            </b-col>
          </b-row>
          <div class="divider"></div>
          <b-form-group
            v-if="coupon.isDisabledCoupon"
            class="text-left"
            id="discount"
            :label="`${$t('form.discountamount')} (${form.DISCOUNTRATE}%)`"
            label-for="discount"
          >
            <b-form-input
              id="discount"
              v-model="form.DISCOUNTAMOUNT"
              trim
              readonly
            ></b-form-input>
          </b-form-group>
          <div class="divider"></div>
          <b-row v-show="activePaymentDoor">
            <b-col cols="6">
              <b-form-input
                v-model="form.PAYMENTDOORPRICE"
                :placeholder="$t('form.payingdooramount')"
              ></b-form-input>
            </b-col>
            <b-col cols="6">
              <b-form-input
                v-model="form.PRODUCTPRICE"
                :placeholder="$t('form.productprice')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="divider"></div>

          <b-row>
            <b-col cols="12">
              <b-form-input
                v-model="form.INSURANCEBASE"
                :placeholder="$t('form.insurancebase')"
              ></b-form-input>
            </b-col>
          </b-row>
          <div class="seperator"></div>

          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span> {{ $t("form.shippingamount") }} </span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ form.PRICE }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span> {{ $t("form.insureanceamount") }} </span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ insurancePay }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span>{{ $t("form.staticprice") }}</span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ form.STATICPRICEAMOUNT }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span>{{ $t("form.boxamount") }}</span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ form.BOXAMOUNT }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span> {{ $t("form.taxamount") }} ({{ form.TAXRATE }}%)</span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ form.TAXAMOUNT }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <b-row class="price-box">
            <b-col class="text-left" cols="6">
              <span> {{ $t("form.netamount") }} </span>
            </b-col>
            <b-col class="text-right" cols="6">
              <span class="price">
                {{ totals.NETAMOUNT }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <div class="seperator"></div>
          <b-row
            class="result-box result-box-green"
            v-if="form.TOTALAMOUNT == 0"
          >
            <b-col class="text-left" cols="8">
              <span>{{ $t("form.fromsenderamount") }}</span>
            </b-col>
            <b-col class="text-right" cols="4">
              <span class="price">
                {{ form.NETAMOUNT }} {{ $t("price.uah") }}
              </span>
            </b-col>
          </b-row>
          <template v-else>
            <b-row class="result-box">
              <b-col class="text-left" cols="8">
                <span> {{ $t("form.frombuyeramount") }} </span>
              </b-col>
              <b-col class="text-right" cols="4">
                <span class="price">
                  {{ form.TOTALAMOUNT }} {{ $t("price.uah") }}
                </span>
              </b-col>
            </b-row>
          </template>
        </b-col>
      </b-row>
    </b-container>

    <b-modal
      v-model="dialog.cancelDialog"
      :cancel-title="$t('buton.docancel')"
      :ok-title="$t('dialog.cancelaction')"
      @show="resetCancelDialog"
      @hidden="resetCancelDialog"
      @ok="handleCancelDialogOk"
    >
      <template #modal-title>{{ $t("dialog.canceltitle") }}</template>
      <div class="d-block">
        <p>{{ $t("dialog.iscancel") }}</p>
        {{ $t("dialog.cancelreason") }}
        <m-input
          nolabel
          v-model="dialog.cancelDialogVariable"
          :placeholder="$t('dialog.cancelreason')"
        />
      </div>
    </b-modal>
    <a :href="printerUrl" v-show="false" ref="printerBtn">Yazdır</a>
    <a :href="printerInvoiceUrl" v-show="false" ref="printerInvoiceBtn"
      >Yazdır</a
    >
    <template slot="footer">
      <b-button
        @click="printAction"
        :disabled="ID == null"
        size="sm"
        squared
        variant="warning"
        >{{ $t("buton.printbarcode") }}</b-button
      >
      <b-button
        @click="printInvoiceAction"
        :disabled="ID == null"
        size="sm"
        squared
        variant="danger"
        v-if="getPerms('printinvoice')"
        >{{ $t("buton.printinvoice") }}</b-button
      >
      <b-button
        @click="cancelCargo"
        :disabled="ID == null || form.ISCANCEL == '1'"
        size="sm"
        squared
        variant="info"
        >{{ $t("buton.docancel") }}</b-button
      >
    </template>
  </m-basic-form>
</template>

<script>
import box1 from "../../assets/box.png";
import api from "../../api";
import { mapGetters } from "vuex";

import swal from "sweetalert";
import config from "../../config";
export default {
  mounted() {
    api.getDefaultInfo().then((res) => {
      if (res.data.status) {
        this.info.senderResellerCode = res.data.data.reseller.RESELLERCODE;
        this.info.senderResellerName = res.data.data.reseller.RESELLERNAME;
      }
    });
    this.getRegion();
    this.getCargoType();
    this.getResellerWithSendingAndPayTypeForCargo();
    this.getPackages();
    this.getBox();
    this.getStaticPrice();

    this.options.resellers.unshift({
      text: this.$t("form.selectreseller"),
      value: null,
      disabled: true,
    });
  },
  data() {
    const cfg = config;
    return {
      isSelectedSenderAddressForm: false,
      isSelectedTakerAddressForm: false,
      loadingShow: false,
      loadingMsg: null,
      buttonLoading: false,

      contract: {
        senderContractQuering: false,
        takerContractQuering: false,
        senderFindingContract: false,
        takerFindingContract: false,
        senderContractData: {},
        takerContractData: {},

        brokerContractQuering: false,
        brokerFindingContract: false,
        brokerContractData: {},
      },

      smsOtp: {
        senderCode: "",

        isLoading: false,
        seconds: 60,
        token: null,
        startingOtp: false,
        isValid: false,
        isLoadingCode: false,

        secondsInterval: null,
      },

      isDisabledDesi: false,
      isBrokerage: false,
      brokerName: null, //isbroker 1 ise hemgönderici hem alıcısı olan için eklendi
      ID: null,

      activePaymentDoor: false,

      dialog: {
        cancelDialog: false,
        cancelDialogVariable: null,
      },

      info: {
        senderResellerCode: "",
        senderResellerName: "",
        takerResellerName: "",
        takerResellerCode: "",
      },

      coupon: {
        COUPONCODE: null,
        isDisabledCoupon: false,
      },

      lookup: {
        presentData: [],
        takerPresentData: [],

        senderAddressData: [],
        takerAddressData: [],

        packageData: [],
        originalPackageData: [],
        originalSendingTypeData: [],
        sendingTypeData: [],
        payTypeData: [],
        sendingTypeTaxRate: 0,
        sendingTypePrice: 0,

        boxData: [],

        staticPrice: [],

        deliveryTypeSpecialPrice: null,
      },

      selected: {
        present: null,
        takerPresent: null,
      },

      isSenderSelectedForm: false,

      senderPresentInfo: {
        ISCOMPANY: "0",
        BANKIBAN: null,
        BANKNAME: null,
        PREFIX: "ФОП",

        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        PRESENTID: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,

        region: null,
        city: null,
        county: null,
        town: null,

        REGIONNAME: null,
        CITYNAME: null,
        COUNTYNAME: null,
        TOWNNAME: null,

        BUILDINGNO: null,
        BUILDINGBLOCKNO: null,
        APARTMENTNO: null,
      },

      isTakerSelectedForm: false,

      takerPresentInfo: {
        ISCOMPANY: "0",
        BANKIBAN: null,
        BANKNAME: null,
        PREFIX: "ФОП",

        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        PRESENTID: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,

        region: null,
        city: null,
        county: null,
        town: null,

        REGIONNAME: null,
        CITYNAME: null,
        COUNTYNAME: null,
        TOWNNAME: null,

        BUILDINGNO: null,
        BUILDINGBLOCKNO: null,
        APARTMENTNO: null,
      },
      isUseDiscountAll: cfg.USE_DISCOUNT_ALL,
      postaServiceCostRate: cfg.POSTA_SERVICE_COST,
      totals: {
        PACKPRICE: 0,
        TAXRATE: 0,
        PAYMENTDOORPRICE: 0,
        BOXPRICE: 0,
        ADDITIONALPAYPRICE: 0,
        INSURANCERATE: 0,
        INSURANCEPRICE: 0,
        ADDITIONALSERVICEPRICE: 0,
        GROSSPRICE: 0,
        DISCOUNTRATE: 0,
        DISCOUNTAMOUNT: 0,
        DISTANCEAMOUT: 0,
        POSTASERVICECOSTRATE: cfg.POSTA_SERVICE_COST,
        POSTASERVICECOST: 0,
        SUBAMOUNT: 0,
        TAXAMOUNT: 0,
        NETAMOUNT: 0,
        PRODUCTPRICE: 0,
        SENDERAMOUNT: 0,
        TAKERAMOUNT: 0,
      },
      form: {
        DELIVERYTYPE: "2",
        DELIVERYRESELLERID: null,
        BOXID: null,
        BOXAMOUNT: 0.0,

        INSURANCEPAY: 0.0,

        ISTRANSFER: 0,

        TRACKINGNO: null,

        ISACCEPTCOUPON: 0,
        COUPONCODE: null,
        CAMPAIGNCOUPONID: null,

        ISRETURN: 0,
        ISINFORMATIONFORM: 0,

        SENDERPRESENT: null,
        TAKERPRESENT: null,

        SENDERPRESENTADDRESS: null,
        TAKERPRESENTADDRESS: null,

        SENDINGTYPEID: null,
        PAYTYPEID: null,
        CARGOTYPEID: null,

        STATICPRICEAMOUNT: 0,
        PRODUCTPRICE: 0,
        PAYMENTDOORPRICE: 0,
        TOTALAMOUNT: 0,

        ISBROKERAGES: 0,
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        PACKAGEID: null,
        SENDERID: null,
        TAKERID: null,
        SENDERADDRESSID: null,
        TAKERADDRESSID: null,
        SENDERRESELLERID: null,
        TAKERRESELLERID: null,
        LINENAME: null,
        DESI: null,
        WEIGHT: null,
        PIECE: 1,
        PRICE: null,
        GROSSAMOUNT: null,
        DISCOUNTRATE: 0,
        DISCOUNTAMOUNT: 0,
        DISTANCEAMOUNT: null,
        TAXRATE: null,
        TAXAMOUNT: null,
        SUBTOTAL: null,
        NETAMOUNT: null,
        BROKERAGEDATE: null,
        BROKERAGETIME: null,
        SAVEDATE: null,
        SAVETIME: null,
        INTEGRATIONBARCODE: null,
        BARCODE: null,
        DESCRIPTION: null,
        LASTSTATUS: null,
        LASTSTATUSDATE: null,
        LASTSTATUSTIME: null,
        ISDELIVERY: null,
        DELIVERYUSERID: null,
        DELIVERYDATE: null,
        DELIVERYTIME: null,
        DELIVEREDFULLNAME: null,
        DELIVEREDIDENTITYNO: null,
        ISTAKERCONTRACT: 0,
        ISSENDERCONTRACT: 0,
        ISBROKERCONTRACT: 0,
        ISCANCEL: 0,

        ISBROKER: 0, //sadece aracılıkmı ediyor?

        UNIQVALUE: null,

        STATICPRICES: [],

        INSURANCEBASE: null,
      },

      options: {
        region: [],
        resellers: [],
        senderCity: [],
        senderCounty: [],
        senderTown: [],
        takerCity: [],
        takerCounty: [],
        takerTown: [],

        cargotype: [],
        paytype: [],
        sendingtype: [],
        package: [],
        box: [],
        staticPrice: [],

        senderAddress: [
          {
            text: this.$t("form.newaddress"),
            value: null,
          },
        ],
        takerAddress: [
          {
            text: this.$t("form.newaddress"),
            value: null,
          },
        ],
      },
    };
  },
  methods: {
    ...mapGetters(["getPerms"]),
    getCalculatedFieldNameForPrice() {
      if (this.takerPresentInfo.REGIONID != this.senderPresentInfo.REGIONID) {
        return "DESIREGIONPRICE";
      } else if (
        this.takerPresentInfo.CITYID != this.senderPresentInfo.CITYID
      ) {
        return "DESICITYPRICE";
      }
      return "DESIPRICE";
    },
    printAction() {
      this.$refs.printerBtn.click();
    },
    printInvoiceAction() {
      this.$refs.printerInvoiceBtn.click();
    },
    resetCancelDialog() {
      this.dialog.cancelDialogVariable = null;
    },
    handleCancelDialogOk() {
      const variable = this.dialog.cancelDialogVariable;
      if (variable != null && variable.length < 3) {
        swal(
          this.$t("dialog.notice"),
          this.$t("validate.canceldescriptionnotnull"),
          "error"
        );
        return;
      }

      this.loadingMsg = this.$t("info.cancellingcargo");
      this.loadingShow = true;
      api
        .setCargoCancel({
          id: this.ID,
          variable,
        })
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.cancelok"),
              "success"
            );
            this.form.ISCANCEL = 1;
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    cancelCargo() {
      if (!this.getPerms("cancelcargo")) {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.noauthforcancel"),
          "error"
        );
        return;
      }
      this.dialog.cancelDialog = true;
    },
    getResellers(id) {
      api
        .getResellersWithTownId(id, this.form.ISTRANSFER)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.resellers = res.data.data.rows.map((item) => {
              return { text: item.RESELLERNAME, value: item.ID };
            });
            this.options.resellers.unshift({
              text: this.$t("form.selectreseller"),
              value: null,
              disabled: true,
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    searchPresent(query) {
      if (query.length < 5) {
        return;
      }

      this.getPresentWithTerm(query).then((res) => {
        if (res.data.status && !res.data.msg) {
          this.lookup.presentData = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FULLNAME || item.FIRMNAME} [${item.PHONE}]`,
              value: item.ID,
            };
          });
        }
      });
      //}, 500);
    },
    takerSearchPresent(query) {
      if (query.length < 5) {
        return;
      }

      this.getPresentWithTerm(query).then((res) => {
        if (res.data.status && !res.data.msg) {
          this.lookup.takerPresentData = res.data.data.map((item) => {
            return {
              ...item,
              text: `${item.FULLNAME || item.FIRMNAME} [${item.PHONE}]`,
              value: item.ID,
            };
          });
        }
      });
      //}, 500);
    },
    getPresentWithTerm(str) {
      return api.getPresentWithTerm(str, -1);
    },

    senderConcatName() {
      this.senderPresentInfo.FULLNAME = [
        this.senderPresentInfo.FIRSTNAME,
        this.senderPresentInfo.LASTNAME,
        this.senderPresentInfo.SECONDNAME,
      ]
        .filter((item) => item)
        .join(" ");
    },

    takerConcatName() {
      this.takerPresentInfo.FULLNAME = [
        this.takerPresentInfo.FIRSTNAME,
        this.takerPresentInfo.LASTNAME,
        this.takerPresentInfo.SECONDNAME,
      ]
        .filter((item) => item)
        .join(" ");
    },

    takerConcatAddress() {
      this.takerPresentInfo.ADDRESS = [
        "буд. :",
        this.takerPresentInfo.BUILDINGNO,
        "корпус :",
        this.takerPresentInfo.BUILDINGBLOCKNO,
        "кв. :",
        this.takerPresentInfo.APARTMENTNO,
      ]
        .filter((item) => item)
        .join(" ");
    },

    senderConcatAddress() {
      this.senderPresentInfo.ADDRESS = [
        "буд. :",

        this.senderPresentInfo.BUILDINGNO,
        "корпус :",

        this.senderPresentInfo.BUILDINGBLOCKNO,
        "кв. :",

        this.senderPresentInfo.APARTMENTNO,
      ]
        .filter((item) => item)
        .join(" ");
    },

    //#region adres
    getRegion() {
      api
        .getRegion()
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options.region = res.data.data.rows.map((item) => {
              return { text: item.REGIONNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeRegion(id, type) {
      api
        .getCityWidthRegionId(id, type)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options[type + "City"] = res.data.data.rows.map((item) => {
              return { text: item.CITYNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCity(id, type) {
      api
        .getCountyWidthCityId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options[type + "County"] = res.data.data.rows.map((item) => {
              return { text: item.COUNTYNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },
    changeCounty(id, type) {
      api
        .getTownWidthCountyId(id)
        .then((res) => {
          if (res.data.status && !res.data.msg) {
            this.options[type + "Town"] = res.data.data.rows.map((item) => {
              return { text: item.TOWNNAME, value: item.ID };
            });
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        });
    },

    //#endregion

    getCargoType() {
      api.getCargoType().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.options.cargotype = res.data.data.rows.map((item) => {
            return {
              text: item.CARGOTYPENAME,
              value: item.ID,
            };
          });
          this.options.cargotype.unshift({
            text: this.$t("form.selectcargotype"),

            value: null,
          });
        }
      });
    },

    getBox() {
      api.getBox().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.boxData = res.data.data.rows;
          this.options.box = res.data.data.rows.map((item) => {
            return {
              text: item.BOXNAME,
              value: item.ID,
            };
          });
          this.options.box.unshift({
            text: this.$t("form.selectbox"),
            value: null,
          });
        }
      });
    },

    getStaticPrice() {
      api.getStaticPrice().then((res) => {
        if (res.data.status && res.data.msg == null) {
          this.lookup.staticPrice = res.data.data.rows;
          const specialPrice = this.lookup.staticPrice.filter(
            (item) => item.ISSPECIALACCOUNT == "1"
          );
          if (specialPrice.length) {
            this.lookup.deliveryTypeSpecialPrice = specialPrice[0].ID;
          }
          this.options.staticPrice = res.data.data.rows.map((item) => {
            return {
              text:
                item.PRICENAME +
                " - " +
                item.PRICE +
                " " +
                this.$t("price.uah"),
              value: item.ID,
            };
          });

          /*
          if (this.lookup.deliveryTypeSpecialPrice) {
            this.$nextTick(() => {
              this.form.STATICPRICES.push(this.lookup.deliveryTypeSpecialPrice);
            });
          }*/
        }
      });
    },

    getResellerWithSendingAndPayTypeForCargo() {
      api.getResellerWithSendingAndPayTypeForCargo().then((res) => {
        if (res.data.status) {
          this.lookup.sendingTypeData = res.data.data.sendingType;
          this.lookup.originalSendingTypeData = res.data.data.sendingType;

          this.lookup.payTypeData = res.data.data.payType;

          //Öncelike ödeme şekli seçilsin sonra burayı yüklüyoruz
          /*
          this.options.sendingtype = this.lookup.sendingTypeData.map((item) => {
            return {
              text: item.SENDINGTYPENAME,
              value: item.ID,
            };
          });
          */
          this.options.sendingtype.unshift({
            text: this.$t("form.selectsendingtype"),
            value: null,
          });

          this.options.paytype = this.lookup.payTypeData.map((item) => {
            return {
              text: item.PAYTYPENAME,
              value: item.ID,
            };
          });
          this.options.paytype.unshift({
            text: this.$t("form.selectpaytype"),
            value: null,
          });
        }
      });
    },

    getPackages() {
      api.getPackageWithDesi().then((res) => {
        if (res.data.data) {
          this.lookup.packageData = res.data.data;
          this.lookup.originalPackageData = res.data.data;
          this.options.package = this.lookup.packageData.map((item) => {
            return {
              text: item.PACKAGENAME,
              value: item.ID,
            };
          });
          this.options.package.unshift({
            text: this.$t("form.selectpackage"),
            value: null,
          });
        }
      });
    },

    resetPrice() {
      this.totals.PACKPRICE = 0;
      this.totals.TAXRATE = 0;
      this.totals.PAYMENTDOORPRICE = 0;
      this.totals.BOXPRICE = 0;
      this.totals.ADDITIONALPAYPRICE = 0;
      this.totals.INSURANCERATE = 0;
      this.totals.INSURANCEPRICE = 0;
      this.totals.ADDITIONALSERVICEPRICE = 0;
      this.totals.GROSSPRICE = 0;
      this.totals.DISCOUNTRATE = 0;
      this.totals.DISCOUNTAMOUNT = 0;
      this.totals.DISTANCEAMOUT = 0;
      this.totals.POSTASERVICECOST = 0;
      this.totals.SUBAMOUNT = 0;
      this.totals.TAXAMOUNT = 0;
      this.totals.NETAMOUNT = 0;
      this.totals.PRODUCTPRICE = 0;
      this.totals.SENDERAMOUNT = 0;
      this.totals.TAKERAMOUNT = 0;

      this.form.PRICE = 0;
      this.form.STATICPRICEAMOUNT = this.totals.ADDITIONALPAYPRICE;
      this.form.BOXAMOUNT = this.totals.BOXPRICE;
      this.form.INSURANCEPAY = this.totals.INSURANCEPRICE;
      this.form.TOTALAMOUNT = this.totals.TAKERAMOUNT;
      this.form.NETAMOUNT = this.totals.SENDERAMOUNT;

      this.form.GROSSAMOUNT = this.totals.GROSSPRICE; //paket ücreti
      this.form.DISCOUNTRATE = this.totals.DISCOUNTRATE; //indirim oranı
      this.form.DISCOUNTAMOUNT = this.totals.DISCOUNTAMOUNT; //indirim tutarı
      this.form.DISTANCEAMOUNT = this.totals.DISTANCEAMOUT; //indirimi düşülmüş paket ücreti
      this.form.POSTASERVICECOST = this.totals.POSTASERVICECOST;
      this.form.SUBTOTAL = this.totals.SUBAMOUNT; //
      this.form.TAXAMOUNT = this.totals.TAXAMOUNT; // paket kdv tutarı
    },

    calcPrice() {
      this.resetPrice();
      let desiOrWeight =
        (parseInt(this.form.DESI) || 0) > (parseInt(this.form.WEIGHT) || 0)
          ? parseInt(this.form.DESI) || 0
          : parseInt(this.form.WEIGHT) || 0;

      if (this.form.PACKAGEID) {
        const packs = this.lookup.packageData.find((item) => {
          return item.ID == this.form.PACKAGEID;
        });
        if (!packs) {
          return;
        }
        if (packs.ISREQUIREDDESI == "1") {
          this.isDisabledDesi = false;
          if (desiOrWeight === 0) {
            desiOrWeight = 1;
          }
          const findedDesi = packs.desi.find((item) => {
            return (
              parseFloat(item.DESISTART) <= parseFloat(desiOrWeight) &&
              parseFloat(desiOrWeight) <= parseFloat(item.DESIEND)
            );
          });

          if (!findedDesi) {
            //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil

            if (packs.DYNAMICDESIUSE == "1") {
              const different =
                parseFloat(desiOrWeight) - parseFloat(packs.DYNAMICDESISTART);
              if (different >= 0) {
                let amount = parseFloat(packs.DYNAMICDESIPRICE);

                amount += different * parseFloat(packs.DYNAMICDESIPERPRICE);

                this.form.PRICE = parseFloat(amount).toFixed(2);
                this.form.TAXRATE = parseFloat(
                  packs.desi[0].DESITAXRATE
                ).toFixed(0);
              } else {
                //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil
                return;
              }
            } else {
              //TODO: desi bulanamadı bilgisi ver ekrana ama burda değil

              return;
            }
          } else {
            this.form.PRICE = parseFloat(findedDesi.DESIPRICE).toFixed(2);
            this.form.TAXRATE = parseFloat(findedDesi.DESITAXRATE).toFixed(0);
          }
        } else {
          this.isDisabledDesi = true;
          this.form.DESI = 0;
          this.form.WEIGHT = 0;

          this.form.PRICE = parseFloat(packs.PRICE).toFixed(2);

          this.form.TAXRATE = parseFloat(packs.TAXRATE).toFixed(0);
        }

        const findedBuyerPaid = this.lookup.payTypeData.find((item) => {
          return (
            item.ID == this.form.PAYTYPEID &&
            item.ISBBUYERPAID.toString() == "1"
          );
        });

        /***********************************yeni ücretler****************************/
        this.totals.PACKPRICE = parseFloat(this.form.PRICE) || 0;

        this.totals.TAXRATE = parseFloat(this.form.TAXRATE) || 0;

        if ((parseFloat(this.lookup.sendingTypePrice) || 0) > 0) {
          this.totals.PAYMENTDOORPRICE = parseFloat(
            this.lookup.sendingTypePrice
          );
        }

        const boxId = this.form.BOXID;
        this.totals.BOXPRICE = 0;
        if ((parseInt(boxId) || 0) > 0) {
          const box = this.lookup.boxData.find((item) => boxId == item.ID);

          if (box) {
            this.totals.BOXPRICE = parseFloat(box.BOXPRICE);
          }
        }

        this.totals.ADDITIONALPAYPRICE = 0;
        if (this.form.STATICPRICES.length) {
          this.totals.ADDITIONALPAYPRICE = this.lookup.staticPrice
            .filter((item) => this.form.STATICPRICES.includes(item.ID))
            .reduce((amount, item) => {
              const price = parseFloat(item.PRICE);

              return parseFloat(amount) + price;
            }, 0);
        }

        this.totals.INSURANCERATE = parseFloat(config.INSURANCE_RATE) || 0;
        this.totals.INSURANCEPRICE = parseFloat(this.insurancePay) || 0;

        this.totals.ADDITIONALSERVICEPRICE =
          this.totals.PAYMENTDOORPRICE +
          this.totals.BOXPRICE +
          this.totals.ADDITIONALPAYPRICE +
          this.totals.INSURANCEPRICE;

        this.totals.GROSSPRICE =
          this.totals.PACKPRICE + this.totals.ADDITIONALSERVICEPRICE;

        this.totals.DISCOUNTRATE = parseFloat(this.form.DISCOUNTRATE) || 0;
        if (this.totals.DISCOUNTRATE > 0) {
          if (this.contract.senderFindingContract && !findedBuyerPaid) {
            this.isUseDiscountAll =
              this.contract.senderContractData.ISUSEDISCOUNTALL == "1"
                ? true
                : false;
          }

          if (this.contract.takerFindingContract && findedBuyerPaid) {
            this.isUseDiscountAll =
              this.contract.takerContractData.ISUSEDISCOUNTALL == "1"
                ? true
                : false;
          }
          if (this.isUseDiscountAll) {
            this.totals.DISCOUNTAMOUNT =
              (this.totals.GROSSPRICE * this.totals.DISCOUNTRATE) / 100;
          } else {
            this.totals.DISCOUNTAMOUNT =
              (this.totals.PACKPRICE * this.totals.DISCOUNTRATE) / 100;
            this.totals.DISCOUNTRATE =
              (this.totals.DISCOUNTAMOUNT / this.totals.GROSSPRICE) * 100;
          }
        }
        this.totals.DISTANCEAMOUT =
          this.totals.GROSSPRICE - this.totals.DISCOUNTAMOUNT;

        this.totals.POSTASERVICECOSTRATE = parseFloat(
          this.postaServiceCostRate
        );

        this.totals.POSTASERVICECOST = parseFloat(
          (this.totals.DISTANCEAMOUT * this.totals.POSTASERVICECOSTRATE) /
            100 || 0
        ).toFixed(2);

        this.totals.SUBAMOUNT = parseFloat(
          (
            this.totals.DISTANCEAMOUT +
            (parseFloat(this.totals.POSTASERVICECOST) || 0)
          ).toFixed(2)
        );

        this.totals.TAXAMOUNT = parseFloat(
          ((this.totals.SUBAMOUNT * this.totals.TAXRATE) / 100).toFixed(2)
        );

        this.totals.NETAMOUNT = parseFloat(
          (this.totals.SUBAMOUNT + this.totals.TAXAMOUNT).toFixed(2)
        );

        this.totals.PRODUCTPRICE = this.form.PRODUCTPRICE;

        if (this.contract.senderFindingContract) {
          if (this.contract.senderContractData.ISPAYPAYMENTDOORTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.PAYMENTDOORPRICE) || 0;
          }
          if (this.contract.senderContractData.ISPAYSHIPPINGPRICETAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.PACKPRICE) || 0;
          }

          if (this.contract.senderContractData.ISPAYBOXAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.BOXPRICE) || 0;
          }

          if (
            this.contract.senderContractData.ISPAYSTATICPRICEAMOUNTTAKER == "1"
          ) {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.ADDITIONALPAYPRICE) || 0;
          }
          if (this.contract.senderContractData.ISPAYINSURANCEPAYTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.INSURANCEPRICE) || 0;
          }

          //indirim posta hizmet bedilinden önce uygulandığı için bu şekilde yapıyoruz.
          if (
            this.contract.senderContractData.ISPAYPOSTASERVICECOSTTAKER == "1"
          ) {
            this.totals.TAKERAMOUNT -= parseFloat(
              (
                (this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
                100
              ).toFixed(2)
            );
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.POSTASERVICECOST) || 0;
          } else {
            this.totals.TAKERAMOUNT -= parseFloat(
              (
                (this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
                100
              ).toFixed(2)
            );
          }

          this.totals.TAKERAMOUNT +=
            (this.totals.TAKERAMOUNT * this.totals.TAXRATE) / 100;
        } else if (this.contract.takerFindingContract && findedBuyerPaid) {
          if (this.contract.takerContractData.ISPAYPAYMENTDOORTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.PAYMENTDOORPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYSHIPPINGPRICETAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.PACKPRICE) || 0;
          }

          if (this.contract.takerContractData.ISPAYBOXAMOUNTTAKER == "1") {
            this.totals.TAKERAMOUNT += parseFloat(this.totals.BOXPRICE) || 0;
          }

          if (
            this.contract.takerContractData.ISPAYSTATICPRICEAMOUNTTAKER == "1"
          ) {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.ADDITIONALPAYPRICE) || 0;
          }
          if (this.contract.takerContractData.ISPAYINSURANCEPAYTAKER == "1") {
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.INSURANCEPRICE) || 0;
          }

          //indirim posta hizmet bedilinden önce uygulandığı için bu şekilde yapıyoruz.
          if (
            this.contract.takerContractData.ISPAYPOSTASERVICECOSTTAKER == "1"
          ) {
            this.totals.TAKERAMOUNT -= parseFloat(
              (
                (this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
                100
              ).toFixed(2)
            );
            this.totals.TAKERAMOUNT +=
              parseFloat(this.totals.POSTASERVICECOST) || 0;
          } else {
            this.totals.TAKERAMOUNT -= parseFloat(
              (
                (this.totals.TAKERAMOUNT * this.totals.DISCOUNTRATE) /
                100
              ).toFixed(2)
            );
          }

          this.totals.TAKERAMOUNT +=
            (this.totals.TAKERAMOUNT * this.totals.TAXRATE) / 100;
        } else if (findedBuyerPaid) {
          this.totals.TAKERAMOUNT = parseFloat(
            this.totals.NETAMOUNT.toFixed(2)
          );
        }

        this.totals.SENDERAMOUNT = (
          this.totals.NETAMOUNT - this.totals.TAKERAMOUNT
        ).toFixed(2);
        this.totals.TAKERAMOUNT += parseFloat(
          toNumberFromTr(this.totals.PRODUCTPRICE).toFixed(2)
        );

        this.totals.TAKERAMOUNT = this.totals.TAKERAMOUNT.toFixed(2);

        this.form.STATICPRICEAMOUNT = this.totals.ADDITIONALPAYPRICE;
        this.form.BOXAMOUNT = this.totals.BOXPRICE;
        this.form.INSURANCEPAY = this.totals.INSURANCEPRICE;
        this.form.TOTALAMOUNT = this.totals.TAKERAMOUNT;
        this.form.NETAMOUNT = this.totals.SENDERAMOUNT;

        this.form.GROSSAMOUNT = this.totals.GROSSPRICE; //paket ücreti
        this.form.DISCOUNTRATE = this.totals.DISCOUNTRATE; //indirim oranı
        this.form.DISCOUNTAMOUNT = this.totals.DISCOUNTAMOUNT; //indirim tutarı
        this.form.DISTANCEAMOUNT = this.totals.DISTANCEAMOUT; //indirimi düşülmüş paket ücreti
        this.form.POSTASERVICECOST = this.totals.POSTASERVICECOST;
        this.form.SUBTOTAL = this.totals.SUBAMOUNT; //
        this.form.TAXAMOUNT = this.totals.TAXAMOUNT; // paket kdv tutarı
      }
    },

    //#region sözleşme

    senderChangePresent(data) {
      this.form.PAYTYPEID = null;

      this.contract.senderFindingContract = false;

      this.contract.senderContractQuering = true;
      api
        .isContract(data)
        .then((res) => {
          if (res.data.status) {
            this.contract.senderFindingContract = true;
            this.contract.senderContractData = res.data.data;
          }
        })
        .finally(() => {
          this.contract.senderContractQuering = false;
        });
    },
    takerChangePresent(data) {
      this.form.PAYTYPEID = null;

      this.contract.takerFindingContract = false;
      this.contract.takerContractQuering = true;
      api
        .isContract(data)
        .then((res) => {
          if (res.data.status) {
            this.contract.takerFindingContract = true;
            this.contract.takerContractData = res.data.data;
          }
        })
        .finally(() => {
          this.contract.takerContractQuering = false;
        });
    },

    //#endregion

    //#region kupon

    checkCouponCode() {
      if (this.coupon.COUPONCODE) {
        api
          .getCampaignCouponWithCouponCode(this.coupon.COUPONCODE)
          .then((res) => {
            if (res.data.status) {
              const coupon = res.data.data;

              this.coupon.isDisabledCoupon = true;
              this.form.COUPONCODE = coupon.COUPONCODE;
              this.form.CAMPAIGNCOUPONID = coupon.ID;
              this.form.ISACCEPTCOUPON = 1;
              this.form.DISCOUNTRATE = parseFloat(coupon.DISCOUNTRATE).toFixed(
                0
              );
            } else {
              swal(
                this.$t("dialog.notice"),
                this.$t("dialog.invalidcouponcode"),
                "error"
              );
            }
          });
      } else {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.couponcodenotnull"),
          "error"
        );
      }
    },

    //#endregion

    changeLine(id) {
      if (id) {
        api.getCityOne(id).then((res) => {
          if (res.data.data) {
            this.form.LINENAME = res.data.data.CITYNAME + " Hattı";
          }
        });
      } else {
        this.form.LINENAME = null;
      }
    },

    senderClearForm() {
      this.isSenderSelectedForm = false;
      this.contract.senderContractQuering = false;
      this.contract.senderFindingContract = false;
      this.contract.senderContractData = {};
      this.form.PAYTYPEID = null;
      this.form.SENDERID = null;

      this.senderPresentInfo = {
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        PRESENTID: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,

        region: null,
        city: null,
        county: null,
        town: null,

        REGIONNAME: null,
        CITYNAME: null,
        COUNTYNAME: null,
        TOWNNAME: null,

        BUILDINGNO: null,
        BUILDINGBLOCKNO: null,
        APARTMENTNO: null,

        ISCOMPANY: "0",
        BANKIBAN: null,
        BANKNAME: null,
        PREFIX: "ФОП",
      };

      /*
      this.options.address = [
        {
          text: this.$t("form.newaddress"),
          value: null,
        },
      ];*/
      //this.lookup.addressData = [];
      //this.lookup.presentData = [];
      this.selected.present = null;
    },

    takerClearForm() {
      this.isTakerSelectedForm = false;
      this.contract.takerContractQuering = false;
      this.contract.takerFindingContract = false;
      this.contract.takerContractData = {};
      this.form.PAYTYPEID = null;
      this.form.TAKERID = null;
      //this.isSelectedAddressForm = false;
      this.takerPresentInfo = {
        BROKERAGEID: null,
        BROKERAGEAGENTID: null,
        ISBROKERAGEPRESENT: 0,
        FIRMNAME: null,
        PRESENTID: null,
        FULLNAME: null,
        IDENTITYNO: null,
        TAXOFFICE: null,
        PHONE: null,
        GSM: null,
        ADDRESSID: null,
        COUNTRYID: null,
        REGIONID: null,
        CITYID: null,
        TOWNID: null,
        COUNTYID: null,
        ADDRESS: null,

        FIRSTNAME: null,
        SECONDNAME: null,
        LASTNAME: null,

        region: null,
        city: null,
        county: null,
        town: null,

        REGIONNAME: null,
        CITYNAME: null,
        COUNTYNAME: null,
        TOWNNAME: null,

        BUILDINGNO: null,
        BUILDINGBLOCKNO: null,
        APARTMENTNO: null,

        ISCOMPANY: "0",
        BANKIBAN: null,
        BANKNAME: null,
        PREFIX: "ФОП",
      };

      /*
      this.options.address = [
        {
          text: this.$t("form.newaddress"),
          value: null,
        },
      ];*/
      //this.lookup.addressData = [];
      //this.lookup.presentData = [];
      this.selected.takerPresent = null;
    },

    changeReseller(id) {
      if (id) {
        api.getResellerWithTownId(id, this.form.ISTRANSFER).then((res) => {
          if (res.data.status) {
            this.info.takerResellerName = res.data.data.RESELLERNAME;
            this.info.takerResellerCode = res.data.data.RESELLERCODE;
            this.form.TAKERRESELLERID = res.data.data.ID;
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.notfoundagent"),
              "error"
            );
          }
        });
      } else {
        this.info.takerResellerName = "";
        this.info.takerResellerCode = "";
        this.form.TAKERRESELLERID = null;
      }
    },

    validateSenderPresent() {
      if (!this.senderPresentInfo.PRESENTID) {
        if (!this.senderPresentInfo.PHONE) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.phonenotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.smsOtp.isValid) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.smscodenotvalid"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        }

        if (this.senderPresentInfo.ISCOMPANY == "0") {
          if (!this.senderPresentInfo.FIRSTNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.firstname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          } else if (!this.senderPresentInfo.LASTNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.lastname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          }
        } else {
          if (!this.senderPresentInfo.FIRMNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.firmname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          }
        }
      }

      if (!this.senderPresentInfo.ADDRESSID) {
        if (!this.senderPresentInfo.region) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addressregionnotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.senderPresentInfo.city) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addresscitynotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.senderPresentInfo.county) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addresscountynotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.senderPresentInfo.town) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addressneighborhoodnotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.senderPresentInfo.ADDRESS) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addressnotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        }

        this.senderPresentInfo.REGIONID = this.senderPresentInfo.region.value;
        this.senderPresentInfo.CITYID = this.senderPresentInfo.city.value;
        this.senderPresentInfo.COUNTYID = this.senderPresentInfo.county.value;
        this.senderPresentInfo.TOWNID = this.senderPresentInfo.town.value;
      }

      return true;
    },

    validateTakerPresent() {
      if (!this.takerPresentInfo.PRESENTID) {
        if (!this.takerPresentInfo.PHONE) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.phonenotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        }
        if (this.takerPresentInfo.ISCOMPANY == "0") {
          if (!this.takerPresentInfo.FIRSTNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.firstname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          } else if (!this.takerPresentInfo.LASTNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.lastname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          }
        } else {
          if (!this.takerPresentInfo.FIRMNAME) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.firmname"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          }
        }
      }

      if (!this.takerPresentInfo.ADDRESSID) {
        if (!this.takerPresentInfo.region) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addressregionnotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.takerPresentInfo.city) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addresscitynotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.takerPresentInfo.county) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addresscountynotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        } else if (!this.takerPresentInfo.town) {
          swal(
            this.$t("dialog.notice"),
            this.$t("validate.addressneighborhoodnotnull"),
            "error"
          );
          this.buttonLoading = false;

          return false;
        }

        if (this.form.DELIVERYTYPE == "1") {
          if (!this.takerPresentInfo.ADDRESS) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.addressnotnull"),
              "error"
            );
            this.buttonLoading = false;

            return false;
          }
        }

        this.takerPresentInfo.REGIONID = this.takerPresentInfo.region.value;
        this.takerPresentInfo.CITYID = this.takerPresentInfo.city.value;
        this.takerPresentInfo.COUNTYID = this.takerPresentInfo.county.value;
        this.takerPresentInfo.TOWNID = this.takerPresentInfo.town.value;
      }

      return true;
    },

    //#region form işlemleri
    save() {
      this.buttonLoading = true;
      if (intval(this.ID) > 0) {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.noauthforedit"),
          "warning"
        );
        this.buttonLoading = false;
        return;
      }

      this.form.ISTAKERCONTRACT = this.contract.takerFindingContract ? 1 : 0;
      this.form.ISSENDERCONTRACT = this.contract.senderFindingContract ? 1 : 0;
      this.form.ISBROKERCONTRACT = this.contract.brokerFindingContract ? 1 : 0;

      if (this.validateSenderPresent()) {
        if (this.validateTakerPresent()) {
          if (!this.form.PACKAGEID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.packagenotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (!this.form.PIECE) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.cargopiecenotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (this.form.DESI == null) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.decinotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (this.form.WEIGHT == null) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.weightnotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (this.form.DELIVERYTYPE == "1") {
            if (!this.form.TAKERRESELLERID) {
              swal(
                this.$t("dialog.notice"),
                this.$t("validate.takeragentnotfound"),
                "error"
              );
              this.buttonLoading = false;
              return;
            }
          } else {
            if (!this.form.DELIVERYRESELLERID) {
              swal(
                this.$t("dialog.notice"),
                this.$t("validate.takeragentnotfound"),
                "error"
              );
              this.buttonLoading = false;
              return;
            }
            this.form.TAKERRESELLERID = this.form.DELIVERYRESELLERID;
          }

          if (!this.form.PAYTYPEID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.paytypenotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (!this.form.SENDINGTYPEID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.sendingtypenotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (!this.form.CARGOTYPEID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.cargotypenotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          if (!this.form.BOXID) {
            swal(
              this.$t("dialog.notice"),
              this.$t("validate.boxnotnull"),
              "error"
            );
            this.buttonLoading = false;
            return;
          }

          const findedSendingTypePaymentDoor = this.lookup.sendingTypeData.find(
            (item) =>
              item.ID.toString() == this.form.SENDINGTYPEID.toString() &&
              item.ISPAYMENTDOOR.toString() == "1"
          );

          if (findedSendingTypePaymentDoor) {
            const findedPayTypeBuyerPaid = this.lookup.payTypeData.find(
              (item) =>
                item.ID.toString() == this.form.PAYTYPEID.toString() &&
                item.ISBBUYERPAID.toString() == "1"
            );

            if (!findedPayTypeBuyerPaid) {
              swal(
                this.$t("dialog.notice"),
                this.$t("validate.paymentindoorerror"),
                "error"
              );
              this.buttonLoading = false;
              return;
            }
          }

          const senderPresent = this.senderPresentInfo;

          delete senderPresent.REGIONNAME;
          delete senderPresent.CITYNAME;
          delete senderPresent.COUNTYNAME;
          delete senderPresent.TOWNNAME;

          senderPresent.PHONE = this.convert2PhoneE164(
            this.senderPresentInfo.PHONE
          );

          const takerPresent = this.takerPresentInfo;

          delete takerPresent.REGIONNAME;
          delete takerPresent.CITYNAME;
          delete takerPresent.COUNTYNAME;
          delete takerPresent.TOWNNAME;

          takerPresent.PHONE = this.convert2PhoneE164(
            this.takerPresentInfo.PHONE
          );

          if (senderPresent.ISBROKERAGEPRESENT) {
            this.form.ISBROKERAGES = 1;
            this.form.BROKERAGEID = senderPresent.BROKERAGEID;
            this.form.BROKERAGEAGENTID = senderPresent.BROKERAGEAGENTID;
          }

          if (senderPresent.PRESENTID) {
            this.form.SENDERID = senderPresent.PRESENTID;
          } else {
            this.form.SENDERPRESENT = senderPresent;
          }

          if (senderPresent.ADDRESSID) {
            this.form.SENDERADDRESSID = senderPresent.ADDRESSID;
          } else {
            this.form.SENDERPRESENTADDRESS = senderPresent;
          }

          if (takerPresent.PRESENTID) {
            this.form.TAKERID = takerPresent.PRESENTID;
          } else {
            this.form.TAKERPRESENT = takerPresent;
          }

          if (takerPresent.ADDRESSID) {
            this.form.TAKERADDRESSID = takerPresent.ADDRESSID;
          } else {
            this.form.TAKERPRESENTADDRESS = takerPresent;
          }

          this.form.INSURANCEPAY = this.insurancePay;

          this.loadingMsg = this.$t("dialog.fetchingdata");
          this.loadingShow = true;

          api
            .saveCargo(
              {
                ...this.form,
                PRODUCTPRICE: toNumberFromTr(this.form.PRODUCTPRICE),
                totals: this.totals,
              },
              this.ID
            )
            .then((res) => {
              if (res.data.status) {
                if (this.ID == res.data.data.ID) {
                  swal(
                    this.$t("dialog.success"),
                    this.$t("dialog.successfullyupdatedrecord"),
                    "success"
                  );
                } else {
                  this.ID = res.data.data.ID;
                  swal({
                    icon: "success",
                    title: this.$t("dialog.success"),
                    text: this.$t("dialog.isprintbarcode"),
                    buttons: [this.$t("dialog.cancel"), this.$t("dialog.yes")],
                  })
                    .then((isOk) => {
                      if (isOk) {
                        this.printAction();
                      }
                    })
                    .finally(() => {
                      swal({
                        icon: "success",
                        title: this.$t("dialog.success"),
                        text: this.$t("dialog.isprintinvoice"),
                        buttons: [
                          this.$t("dialog.cancel"),
                          this.$t("dialog.yes"),
                        ],
                      }).then((isOkInvoice) => {
                        if (isOkInvoice) {
                          this.printInvoiceAction();
                        }
                      });
                    });
                }
              } else {
                swal(
                  this.$t("dialog.notice"),
                  this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
                  "error"
                );
              }
              this.buttonLoading = false;
            })
            .catch((err) => {
              swal(
                this.$t("dialog.systemerror"),
                this.$t("dialog.anerrorhasccurred") + err.toString(),
                "error"
              );
              this.buttonLoading = false;
            })
            .finally(() => {
              this.loadingShow = false;
            });
        }
      }
    },

    remove() {
      swal(
        this.$t("dialog.notice"),
        this.$t("dialog.noauthforremove"),
        "warning"
      );
      return;
      this.loadingMsg = this.$t("dialog.removingrecord");
      this.loadingShow = true;
      api
        .deleteCargo(this.ID)
        .then((res) => {
          if (res.data.status && res.data.msg) {
            swal(this.$t("dialog.info"), res.data.msg, "info");
          } else {
            swal(
              this.$t("dialog.notice"),
              this.$t("dialog.anerrorhasccurred") + res.data.errMsg,
              "error"
            );
          }
        })
        .catch((err) => {
          swal(
            this.$t("dialog.systemerror"),
            this.$t("dialog.anerrorhasccurred") + err.toString(),
            "error"
          );
        })
        .finally(() => {
          this.loadingShow = false;
        });
    },
    //#endregion

    //#region
    getAddress(id, type) {
      api.getPresentAddressWithPresentId(id).then((res) => {
        if (res.data.status) {
          this.lookup[type + "AddressData"] = res.data.data.rows;
          if (this.lookup[type + "AddressData"].length) {
            this[type + "PresentInfo"].ADDRESSID = this.lookup[
              type + "AddressData"
            ][0].ID;
          }

          this.options[type + "Address"] = res.data.data.rows.map((item) => {
            return {
              text: item.ADDRESSNAME,
              value: item.ID,
            };
          });
        }
      });
    },
    //#endregion

    senderNewAddress() {
      if (
        this.options.senderAddress.length &&
        this.options.senderAddress[0].value != null
      ) {
        this.options.senderAddress.unshift({
          text: this.$t("form.newaddress"),
          value: null,
        });
      }
      this.isSelectedSenderAddressForm = false;
      this.senderPresentInfo.ADDRESSID = null;
      this.senderPresentInfo.COUNTRYID = null;
      this.senderPresentInfo.REGIONID = null;
      this.senderPresentInfo.CITYID = null;
      this.senderPresentInfo.TOWNID = null;
      this.senderPresentInfo.COUNTYID = null;
      this.senderPresentInfo.ADDRESS = null;

      this.senderPresentInfo.REGIONNAME = null;
      this.senderPresentInfo.CITYNAME = null;
      this.senderPresentInfo.COUNTYNAME = null;
      this.senderPresentInfo.TOWNNAME = null;

      this.senderPresentInfo.BUILDINGNO = null;
      this.senderPresentInfo.BUILDINGBLOCKNO = null;
      this.senderPresentInfo.APARTMENTNO = null;

      this.senderPresentInfo.region = null;
      this.senderPresentInfo.city = null;
      this.senderPresentInfo.county = null;
      this.senderPresentInfo.town = null;
    },

    takerNewAddress() {
      if (
        this.options.takerAddress.length &&
        this.options.takerAddress[0].value != null
      ) {
        this.options.takerAddress.unshift({
          text: this.$t("form.newaddress"),
          value: null,
        });
      }
      this.isSelectedTakerAddressForm = false;
      this.takerPresentInfo.ADDRESSID = null;
      this.takerPresentInfo.COUNTRYID = null;
      this.takerPresentInfo.REGIONID = null;
      this.takerPresentInfo.CITYID = null;
      this.takerPresentInfo.TOWNID = null;
      this.takerPresentInfo.COUNTYID = null;
      this.takerPresentInfo.ADDRESS = null;

      this.takerPresentInfo.REGIONNAME = null;
      this.takerPresentInfo.CITYNAME = null;
      this.takerPresentInfo.COUNTYNAME = null;
      this.takerPresentInfo.TOWNNAME = null;

      this.takerPresentInfo.BUILDINGNO = null;
      this.takerPresentInfo.BUILDINGBLOCKNO = null;
      this.takerPresentInfo.APARTMENTNO = null;

      this.takerPresentInfo.region = null;
      this.takerPresentInfo.city = null;
      this.takerPresentInfo.county = null;
      this.takerPresentInfo.town = null;

      this.form.DELIVERYRESELLERID = null;
      this.changeReseller(0);
    },

    requestSenderCode() {
      const phone = this.convert2PhoneE164(this.senderPresentInfo.PHONE);
      if (phone.length != 13) {
        swal(this.$t("dialog.notice"), this.$t("dialog.invalidphone"), "error");
        return;
      }
      this.smsOtp.isLoading = true;
      api
        .requestSms(phone)
        .then((res) => {
          if (res.data.status) {
            this.smsOtp.startingOtp = true;
            this.smsOtp.token = res.data.data.token;
            this.smsOtp.secondsInterval = setInterval(() => {
              this.smsOtp.seconds--;
              if (this.smsOtp.seconds < 1) {
                this.smsOtp.startingOtp = false;
                clearInterval(this.smsOtp.secondsInterval);
                this.smsOtp.seconds = 60;
              }
            }, 1000);
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.succcesssmssend"),
              "success"
            );
          } else {
            swal(this.$t("dialog.notice"), res.data.errMsg, "error");
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.smsOtp.isLoading = false;
        });
    },
    validateCode() {
      if (!this.senderState) {
        swal(
          this.$t("dialog.notice"),
          this.$t("dialog.invalidsmscode"),
          "error"
        );
        return;
      }

      this.smsOtp.isLoadingCode = true;

      api
        .doSmsOtp(this.smsOtp.token, this.smsOtp.senderCode)
        .then((res) => {
          if (res.data.status) {
            swal(
              this.$t("dialog.success"),
              this.$t("dialog.succcessphonevalidation"),
              "success"
            );

            this.smsOtp.isValid = true;
            this.smsOtp.startingOtp = false;
            clearInterval(this.smsOtp.secondsInterval);
            this.smsOtp.seconds = 60;
          } else {
            swal(this.$t("dialog.notice"), res.data.errMsg, "error");
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.smsOtp.isLoadingCode = false;
        });
    },
    convert2PhoneE164(data) {
      return data.replace(/[\(\)\s]/g, "");
    },
  },
  computed: {
    printerUrl() {
      return `prdr://invoicebarcode_temp%20ID=${this.ID}%200`;
    },
    printerInvoiceUrl() {
      return `prdr://invoice_temp%20ID=${this.ID}%200`;
    },
    boxImage() {
      return box1;
    },
    senderState() {
      return this.smsOtp.senderCode.length == 6;
    },

    insurancePay() {
      let price = parseFloat(this.form.INSURANCEBASE) || 0;
      if (price > 0) {
        return (
          (price * (parseFloat(config.INSURANCE_RATE) || 0)) /
          100
        ).toFixed(2);
      }
      return (0).toFixed(2);
    },
  },

  watch: {
    "selected.present"(val) {
      if (val) {
        this.isSenderSelectedForm = true;

        this.form.SENDERID = val.ID;

        this.senderPresentInfo.PRESENTID = val.ID;
        this.senderPresentInfo.FIRSTNAME = val.FIRSTNAME;
        this.senderPresentInfo.LASTNAME = val.LASTNAME;
        this.senderPresentInfo.SECONDNAME = val.SECONDNAME;

        this.senderPresentInfo.FIRMNAME = val.FIRMNAME;
        this.senderPresentInfo.FULLNAME = val.FULLNAME;
        this.senderPresentInfo.TAXOFFICE = val.TAXOFFICE;
        this.senderPresentInfo.IDENTITYNO = val.IDENTITYNO;
        this.senderPresentInfo.GSM = val.GSM;
        this.senderPresentInfo.PHONE = val.PHONE;

        this.senderPresentInfo.ISCOMPANY = val.ISCOMPANY;
        this.senderPresentInfo.BANKIBAN = val.BANKIBAN;
        this.senderPresentInfo.BANKNAME = val.BANKNAME;
        this.senderPresentInfo.PREFIX = val.PREFIX;

        this.getAddress(val.ID, "sender");
        this.senderChangePresent({ id: val.ID, type: "present" });
      } else {
        this.senderClearForm();
      }
    },

    "senderPresentInfo.FIRSTNAME"() {
      this.senderConcatName();
    },
    "senderPresentInfo.SECONDNAME"() {
      this.senderConcatName();
    },
    "senderPresentInfo.LASTNAME"() {
      this.senderConcatName();
    },

    "senderPresentInfo.region"(val) {
      this.options.senderCity = [];
      this.options.senderCounty = [];
      this.options.senderTown = [];

      this.senderPresentInfo.city = null;
      this.senderPresentInfo.county = null;
      this.senderPresentInfo.town = null;

      this.senderPresentInfo.REGIONID = null;

      if (val) {
        this.senderPresentInfo.REGIONID = val.value;

        this.changeRegion(val.value, "sender");
      }
    },
    "senderPresentInfo.city"(val) {
      this.options.senderCounty = [];
      this.options.senderTown = [];

      this.senderPresentInfo.county = null;
      this.senderPresentInfo.town = null;

      this.senderPresentInfo.CITYID = null;

      if (val) {
        this.senderPresentInfo.CITYID = val.value;

        this.changeCity(val.value, "sender");
      }
    },
    "senderPresentInfo.county"(val) {
      this.options.senderTown = [];

      this.senderPresentInfo.town = null;
      if (val) {
        this.changeCounty(val.value, "sender");
      }
    },

    "selected.takerPresent"(val) {
      this.isTakerSelectedForm = true;

      if (val) {
        this.form.TAKERID = val.ID;

        this.takerPresentInfo.PRESENTID = val.ID;
        this.takerPresentInfo.FIRSTNAME = val.FIRSTNAME;
        this.takerPresentInfo.LASTNAME = val.LASTNAME;
        this.takerPresentInfo.SECONDNAME = val.SECONDNAME;

        this.takerPresentInfo.FIRMNAME = val.FIRMNAME;
        this.takerPresentInfo.FULLNAME = val.FULLNAME;
        this.takerPresentInfo.TAXOFFICE = val.TAXOFFICE;
        this.takerPresentInfo.IDENTITYNO = val.IDENTITYNO;
        this.takerPresentInfo.GSM = val.GSM;
        this.takerPresentInfo.PHONE = val.PHONE;

        this.takerPresentInfo.ISCOMPANY = val.ISCOMPANY;
        this.takerPresentInfo.BANKIBAN = val.BANKIBAN;
        this.takerPresentInfo.BANKNAME = val.BANKNAME;
        this.takerPresentInfo.PREFIX = val.PREFIX;

        this.getAddress(val.ID, "taker");
        this.takerChangePresent({ id: val.ID, type: "present" });
      } else {
        this.takerClearForm();
      }
    },

    "takerPresentInfo.FIRSTNAME"() {
      this.takerConcatName();
    },
    "takerPresentInfo.SECONDNAME"() {
      this.takerConcatName();
    },
    "takerPresentInfo.LASTNAME"() {
      this.takerConcatName();
    },

    "takerPresentInfo.BUILDINGNO"() {
      this.takerConcatAddress();
    },
    "takerPresentInfo.BUILDINGBLOCKNO"() {
      this.takerConcatAddress();
    },
    "takerPresentInfo.APARTMENTNO"() {
      this.takerConcatAddress();
    },

    "senderPresentInfo.BUILDINGNO"() {
      this.senderConcatAddress();
    },
    "senderPresentInfo.BUILDINGBLOCKNO"() {
      this.senderConcatAddress();
    },
    "senderPresentInfo.APARTMENTNO"() {
      this.senderConcatAddress();
    },

    "takerPresentInfo.region"(val) {
      this.options.takerCity = [];
      this.options.takerCounty = [];
      this.options.takerTown = [];
      this.options.resellers = [];

      this.options.resellers.unshift({
        text: this.$t("form.selectreseller"),
        value: null,
        disabled: true,
      });

      this.form.DELIVERYRESELLERID = null;

      this.takerPresentInfo.city = null;
      this.takerPresentInfo.county = null;
      this.takerPresentInfo.town = null;

      this.takerPresentInfo.REGIONID = null;
      if (val) {
        this.takerPresentInfo.REGIONID = val.value;
        this.changeRegion(val.value, "taker");
      }
    },
    "takerPresentInfo.city"(val) {
      this.options.takerCounty = [];
      this.options.takerTown = [];
      this.options.resellers = [];
      this.options.resellers.unshift({
        text: this.$t("form.selectreseller"),
        value: null,
        disabled: true,
      });
      this.form.DELIVERYRESELLERID = null;

      this.takerPresentInfo.county = null;
      this.takerPresentInfo.town = null;

      this.takerPresentInfo.CITYID = null;

      if (val) {
        this.takerPresentInfo.CITYID = val.value;

        this.changeLine(val.value);
        this.changeCity(val.value, "taker");
      }
    },
    "takerPresentInfo.county"(val) {
      this.options.takerTown = [];
      this.options.resellers = [];
      this.options.resellers.unshift({
        text: this.$t("form.selectreseller"),
        value: null,
        disabled: true,
      });
      this.form.DELIVERYRESELLERID = null;

      this.takerPresentInfo.town = null;
      if (val) {
        this.changeCounty(val.value, "taker");
      }
    },
    "takerPresentInfo.town"(val) {
      this.form.DELIVERYRESELLERID = null;
      this.changeReseller(val.value || 0);

      if (val) {
        this.getResellers(val.value);
      }
    },

    //#region fiyat işlemleri
    "form.SENDINGTYPEID"(val) {
      this.activePaymentDoor = false;
      this.form.PAYMENTDOORPRICE = 0;
      this.lookup.sendingTypePrice = null;
      this.lookup.sendingTypeTaxRate = null;
      if (val) {
        const findedSendingTypePaymentDoor = this.lookup.sendingTypeData.find(
          (item) =>
            item.ID.toString() == val.toString() &&
            item.ISPAYMENTDOOR.toString() == "1"
        );
        if (findedSendingTypePaymentDoor) {
          this.activePaymentDoor = true;
          this.lookup.sendingTypePrice =
            findedSendingTypePaymentDoor.SENDINGTYPEPRICE;
          this.lookup.sendingTypeTaxRate =
            findedSendingTypePaymentDoor.SENDINGTYPETAXRATE;
          this.form.PAYMENTDOORPRICE = parseFloat(
            parseFloat(this.lookup.sendingTypePrice) *
              (1 + parseFloat(this.lookup.sendingTypeTaxRate) / 100)
          ).toFixed(2);
        }
      }
    },

    "form.PAYTYPEID"(val) {
      this.form.SENDINGTYPEID = null;
      this.form.PACKAGEID = null;
      let findedBuyerPaid = undefined;
      if (val) {
        findedBuyerPaid = this.lookup.payTypeData.find((item) => {
          return item.ID == val && item.ISBBUYERPAID.toString() == "1";
        });
      }

      this.calcPrice();
      this.lookup.sendingTypeData = this.lookup.originalSendingTypeData;
      this.options.sendingtype = this.lookup.sendingTypeData
        .filter((item) => {
          if (findedBuyerPaid) {
            return true;
          } else {
            return item.ISSTANDARTSENDING == "1";
          }
        })
        .map((item) => {
          return {
            text: item.SENDINGTYPENAME,
            value: item.ID,
          };
        });
      this.options.sendingtype.unshift({
        text: this.$t("form.selectsendingtype"),
        value: null,
        disabled: true,
      });
      this.lookup.packageData = this.lookup.originalPackageData;
      this.options.package = this.lookup.packageData.map((item) => {
        return {
          text: item.PACKAGENAME,
          value: item.ID,
        };
      });

      this.options.package.unshift({
        text: this.$t("form.selectpackage"),
        value: null,
        disabled: true,
      });

      if (val && this.contract.brokerFindingContract) {
        this.lookup.sendingTypeData = this.contract.brokerContractData.sendingtypes;
        this.options.sendingtype = this.contract.brokerContractData.sendingtypes.map(
          (item) => {
            return {
              text: item.SENDINGTYPENAME,
              value: item.ID,
            };
          }
        );
        this.options.sendingtype.unshift({
          text: this.$t("form.selectsendingtype"),
          value: null,
          disabled: true,
        });
        this.lookup.packageData = this.contract.brokerContractData.packages;
        this.options.package = this.contract.brokerContractData.packages.map(
          (item) => {
            return {
              text: item.PACKAGENAME,
              value: item.ID,
            };
          }
        );
        this.options.package.unshift({
          text: this.$t("form.selectpackage"),
          value: null,
          disabled: true,
        });
        this.calcPrice();
      } else {
        if (
          val &&
          (this.contract.senderFindingContract ||
            this.contract.takerFindingContract)
        ) {
          if (
            findedBuyerPaid &&
            this.contract.takerFindingContract &&
            !this.isBrokerage
          ) {
            this.lookup.sendingTypeData = this.contract.takerContractData.sendingtypes;
            this.options.sendingtype = this.contract.takerContractData.sendingtypes.map(
              (item) => {
                return {
                  text: item.SENDINGTYPENAME,
                  value: item.ID,
                };
              }
            );
            this.options.sendingtype.unshift({
              text: this.$t("form.selectsendingtype"),
              value: null,
              disabled: true,
            });
            this.lookup.packageData = this.contract.takerContractData.packages;
            this.options.package = this.contract.takerContractData.packages.map(
              (item) => {
                return {
                  text: item.PACKAGENAME,
                  value: item.ID,
                };
              }
            );
            this.options.package.unshift({
              text: this.$t("form.selectpackage"),
              value: null,
              disabled: true,
            });
            this.calcPrice();
          } else {
            if (!findedBuyerPaid && this.contract.senderFindingContract) {
              this.lookup.sendingTypeData = this.contract.senderContractData.sendingtypes;
              this.options.sendingtype = this.contract.senderContractData.sendingtypes
                .filter((item) => {
                  if (findedBuyerPaid) {
                    return true;
                  } else {
                    return item.ISSTANDARTSENDING == "1";
                  }
                })
                .map((item) => {
                  return {
                    text: item.SENDINGTYPENAME,
                    value: item.ID,
                  };
                });
              this.options.sendingtype.unshift({
                text: this.$t("form.selectsendingtype"),
                value: null,
                disabled: true,
              });
              this.lookup.packageData = this.contract.senderContractData.packages;
              this.options.package = this.contract.senderContractData.packages.map(
                (item) => {
                  return {
                    text: item.PACKAGENAME,
                    value: item.ID,
                  };
                }
              );
              this.options.package.unshift({
                text: this.$t("form.selectpackage"),
                value: null,
                disabled: true,
              });
            } else if (
              findedBuyerPaid &&
              this.contract.senderFindingContract &&
              this.isBrokerage
            ) {
              this.lookup.sendingTypeData = this.contract.senderContractData.sendingtypes;
              this.options.sendingtype = this.contract.senderContractData.sendingtypes
                .filter((item) => {
                  if (findedBuyerPaid) {
                    return true;
                  } else {
                    return item.ISSTANDARTSENDING == "1";
                  }
                })
                .map((item) => {
                  return {
                    text: item.SENDINGTYPENAME,
                    value: item.ID,
                  };
                });
              this.options.sendingtype.unshift({
                text: this.$t("form.selectsendingtype"),
                value: null,
                disabled: true,
              });
              this.lookup.packageData = this.contract.senderContractData.packages;
              this.options.package = this.contract.senderContractData.packages.map(
                (item) => {
                  return {
                    text: item.PACKAGENAME,
                    value: item.ID,
                  };
                }
              );
              this.options.package.unshift({
                text: this.$t("form.selectpackage"),
                value: null,
                disabled: true,
              });
            }
            this.calcPrice();
          }
        }
      }
    },

    "form.PACKAGEID"() {
      this.calcPrice();
    },

    /**
     * 4Mart2020 piece ile çarpım kaldırıldı.
     *
     */
    /**
          * ? Dolayısıyla  piecenin değişimini takip etmeye gerek kalmadı :)
    "form.PIECE"() {
      this.calcPrice();
    },
    */
    "form.PRODUCTPRICE"(val) {
      this.form.INSURANCEBASE = val;
      this.calcPrice();
    },

    "form.PAYMENTDOORPRICE"() {
      this.calcPrice();
    },
    "form.DISCOUNTRATE"(val, oldVal) {
      if (parseFloat(val) != parseFloat(oldVal)) {
        this.calcPrice();
      }
    },
    "form.DESI"(val, oldVal) {
      this.calcPrice();
    },
    "form.WEIGHT"(val, oldVal) {
      this.calcPrice();
    },
    /**
     * 16Nisan2021 bölgeye ile göre farklı fiyat değişimleri olsun denildi
     */
    "takerPresentInfo.REGIONID"() {
      this.calcPrice();
    },
    "takerPresentInfo.CITYID"() {
      this.calcPrice();
    },

    "senderPresentInfo.REGIONID"() {
      this.calcPrice();
    },
    "senderPresentInfo.CITYID"() {
      this.calcPrice();
    },

    "form.COUPONCODE"(val) {
      if (val != this.coupon.COUPONCODE) {
        this.coupon.COUPONCODE = val;
        this.coupon.isDisabledCoupon = true;
      }
    },

    "form.STATICPRICES"() {
      this.calcPrice();
    },

    //#endregion

    "form.BOXID"(val) {
      if (val) {
        this.calcPrice();
      }
    },

    "senderPresentInfo.ADDRESSID"(id) {
      if (id) {
        console.log(id);
        this.isSelectedSenderAddressForm = true;
        const index = this.lookup.senderAddressData.findIndex((item) => {
          return item.ID == id;
        });

        const address = this.lookup.senderAddressData[index];
        console.log(address);
        this.senderPresentInfo.COUNTRYID = address.COUNTRYID;
        this.senderPresentInfo.REGIONID = address.REGIONID;
        this.senderPresentInfo.CITYID = address.CITYID;
        this.senderPresentInfo.COUNTYID = address.COUNTYID;
        this.senderPresentInfo.TOWNID = address.TOWNID;
        this.senderPresentInfo.ADDRESS = address.ADDRESS;

        this.senderPresentInfo.REGIONNAME = address.REGIONNAME;
        this.senderPresentInfo.CITYNAME = address.CITYNAME;
        this.senderPresentInfo.COUNTYNAME = address.COUNTYNAME;
        this.senderPresentInfo.TOWNNAME = address.TOWNNAME;

        this.senderPresentInfo.BUILDINGNO = address.BUILDINGNO;
        this.senderPresentInfo.BUILDINGBLOCKNO = address.BUILDINGBLOCKNO;
        this.senderPresentInfo.APARTMENTNO = address.APARTMENTNO;
      } else {
        this.senderNewAddress();
      }
    },

    "takerPresentInfo.ADDRESSID"(id) {
      if (id) {
        this.isSelectedTakerAddressForm = true;
        const index = this.lookup.takerAddressData.findIndex((item) => {
          return item.ID == id;
        });

        const address = this.lookup.takerAddressData[index];

        this.takerPresentInfo.COUNTRYID = address.COUNTRYID;
        this.takerPresentInfo.REGIONID = address.REGIONID;
        this.takerPresentInfo.CITYID = address.CITYID;
        this.takerPresentInfo.COUNTYID = address.COUNTYID;
        this.takerPresentInfo.TOWNID = address.TOWNID;
        this.takerPresentInfo.ADDRESS = address.ADDRESS;

        this.takerPresentInfo.town = {
          value: address.TOWNID,
        };

        this.takerPresentInfo.REGIONNAME = address.REGIONNAME;
        this.takerPresentInfo.CITYNAME = address.CITYNAME;
        this.takerPresentInfo.COUNTYNAME = address.COUNTYNAME;
        this.takerPresentInfo.TOWNNAME = address.TOWNNAME;

        this.takerPresentInfo.BUILDINGNO = address.BUILDINGNO;
        this.takerPresentInfo.BUILDINGBLOCKNO = address.BUILDINGBLOCKNO;
        this.takerPresentInfo.APARTMENTNO = address.APARTMENTNO;

        this.form.DELIVERYRESELLERID = null;
        this.changeReseller(address.TOWNID);
      } else {
        this.takerNewAddress();
      }
    },
    senderState(val) {
      if (val) {
        this.validateCode();
      }
    },
    "form.DELIVERYTYPE"(val) {
      if (val == "1" && this.lookup.deliveryTypeSpecialPrice) {
        const itemIndex = this.form.STATICPRICES.findIndex(
          (item) => item == this.lookup.deliveryTypeSpecialPrice
        );
        if (itemIndex == -1) {
          this.form.STATICPRICES.push(this.lookup.deliveryTypeSpecialPrice);
        }
      } else {
        if (this.lookup.deliveryTypeSpecialPrice) {
          const itemIndex = this.form.STATICPRICES.findIndex(
            (item) => item == this.lookup.deliveryTypeSpecialPrice
          );
          if (itemIndex != -1) {
            this.form.STATICPRICES.splice(itemIndex, 1);
          }
        }
      }
    },
    insurancePay() {
      this.calcPrice();
    },
  },
};
</script>
<style lang="scss" scoped>
.m-app {
  background-color: white !important;
  color: #000;
}
h2 {
  color: #004e9c;
}
.line {
  background-color: #ffcb00;
  width: 43px;
  height: 2px;
  margin: auto;
  margin-bottom: 20px;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder,
#discount label {
  font-size: 14px !important;
  color: #004e9c !important;
}
input,
textarea {
  padding: 30px 20px !important;
  border-radius: 13px !important;
  box-shadow: 0px 0px 3px 0px #4b72ad52 !important;
  border: none !important;
  overflow: hidden !important;
}

.radio-box {
  padding: 18px 20px !important;
  border-radius: 13px !important;
  box-shadow: 0px 0px 3px 0px #4b72ad52 !important;
  border: none !important;
  overflow: hidden !important;
}
.image-box {
  padding: 5px 20px !important;
  border-radius: 13px !important;
  box-shadow: 0px 0px 3px 0px #4b72ad52 !important;
  border: none !important;
  overflow: hidden !important;
  width: 100%;
  height: 58px;
}
.custom-select {
  padding: 13px 20px !important;
  height: calc(1.9em + 1.75rem + 2px) !important;
  box-shadow: 0px 0px 3px 0px #4b72ad52 !important;
  border: none !important;
  border-radius: 13px;
  font-size: 14px !important;
  color: #004e9c !important;
}
.divider {
  margin: 20px;
}
.yellow-button {
  height: 100%;
  border-radius: 13px;
  background-color: #f6ce00;
  font-size: 16;
  color: #fff;
  border: none;
}
.yellow-button:hover {
  background-color: #ddb904;
}
.blue-button {
  height: 100%;
  border-radius: 13px;
  background-color: #004e9c;
  font-size: 16;
  color: #fff;
  border: none;
}
.blue-button:hover {
  background-color: #0262c2;
}
.side-line {
  border-right: 2px solid #004e9c21;
}
.seperator {
  border-bottom: 2px solid #004e9c21;
  margin: 20px 0px;
}
.price-box,
.result-box {
  border-radius: 50px;
  box-shadow: 0px 0px 3px 0px #4b72ad52 !important;
  font-size: 16px !important;
  color: #004e9c;
  padding: 8px 20px;
  margin: 10px 5px;
}
.price-box .price {
  color: #000;
}
.result-box {
  background-color: #004e9c;
  color: #fff;
  padding: 15px 20px;
}
.result-box-green {
  background-color: #089c00;
}
.result-box .price {
  color: #ffcb00;
}
.form-control.is-invalid,
.form-control.is-valid {
  background-position: right calc(1em + 0.1875rem) center;
}
</style>
